import React from 'react';
import './style.scss';

import { Link } from "react-scroll";
const Navigator = ({setSection, section}) => {



    const togglePage = (page) => {
        if(section === 'our-portfolio') {
                setSection(page);
            return
        }
        document.getElementById(page).scrollIntoView({behavior: 'smooth', block: 'start' });
    }
    return (
    <>
        { section === 'our-portfolio' ? 
        null
        :
        <div className='wrapper-navigator-t3d'>
            <div className='container-navigator'>
                <>
                    <Link className='item-navigator' onClick={() => {togglePage('home')}} activeClass="active home-nav" to="home" spy={true} smooth={true} offset={0} duration={1200} >Home</Link>
                    <Link className='item-navigator' onClick={() => {togglePage('recent-works')}}  activeClass="active recent-works-nav" to="recent-works" spy={true} smooth={true} offset={0} duration={1200} >Works</Link>
                    <Link className='item-navigator' onClick={() => {togglePage('services')}}  activeClass="active services-nav" to="services" spy={true} smooth={true} offset={0} duration={1200} >Services</Link>
                    <Link className='item-navigator' onClick={() => {togglePage('contact')}} activeClass="active contact-nav"  to="contact" spy={true}  smooth={true} offset={0} duration={1200} >Contact</Link>
                </>
                <div className='line-active-navigator'><div className={`line`}></div></div>
            </div>
        </ div> }
    </>
        
    );
};
export default Navigator;