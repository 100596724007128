import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowMini} from '../../assets/arrow-mini.svg';
import { ReactComponent as Filter} from '../../assets/filter.svg';
import {ReactComponent as Arrow} from '../../assets/arrow-title.svg'
import ScrollDisabler from '../../ScrollDisabler';
import './style.scss';
import { Link } from 'react-router-dom';


const OurPortfolio = ({state, filters, setFilters, toggleFilter, setToggleFilter}) => {

    const hiddenLoader = () => {
        setTimeout(() => {
            document.getElementById('wrapper-loader').classList.add("hidden");
            setTimeout(() => {
                ScrollDisabler.enable();
            }, 1000)
        }, 3000)
    }

    useEffect(() => {
        hiddenLoader();
        window.scrollTo(0, 0);
    }, [])

    const [filterAcrive, SetfilterActive] = useState(false);
    const addFilter = () => {
        if(document.getElementById('grid-project-portfolio') && (document.getElementById('grid-project-portfolio').getBoundingClientRect().top <= 19 || window.innerWidth  <= 767 || window.innerHeight  <= 640)) {
            SetfilterActive(true)
        } else {
            SetfilterActive(false)
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", addFilter);
        return () => window.removeEventListener("scroll", addFilter);
    }, []);
    useEffect(() => {
        window.addEventListener("resize", addFilter, false);
        return () => window.removeEventListener('resize', addFilter);
    }, []);

    useEffect(() => {
        
        addFilter()
    }, [])

    const chunkArr = (array) => {
        if(filters.length){
            array = array.filter(item => item.tag?.find(el => filters.includes(el)));
        }
        let size = 10; 
        let subarray = []; 
        for (let i = 0; i <Math.ceil(array.length/size); i++){
            subarray[i] = array.slice((i*size), (i*size) + size);
        }
        return subarray;
    }
    const changeFilter = (value) => {
        if(filters.includes(value)){
            setFilters(prev => prev.filter(item => item !== value))
        }else{
            setFilters(prev => [...prev, value])
        }
    }

    return (
        <>
            <div className='wrapper-our-portfolio-t3d' id='our-portfolio'>
                <div className='intro-our-portfolio'>
                    <div className='wrapper-btn-back-works'>
                        <div className='container-btn-back-works'>
                            <Link to='/' className='btn-back-works' onClick={() => {setFilters([])}}>
                                <ArrowMini /> <span>Back</span>
                            </Link>
                        </div>
                    </div>
                    <div className='container-content-our-portfolio'> 
                        <div className="header-content-our-portfolio">
                            <div className='title-header-our-portfolio'>Our <span>Portfolio</span></div>
                        </div>
                        <div className='filter-container-content'>
                            <div className='filter-content'>
                                <div className={filters.includes("product") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('product')} >Product</div>
                                <div className={filters.includes("configurator") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('configurator')} >Configurator</div>
                                <div className={filters.includes("ar") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('ar')} >AR</div>
                                <div className={filters.includes("crypto") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('crypto')} >Crypto</div>
                                <div className={filters.includes("construction") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('construction')} >Construction</div>
                                <div className={filters.includes("website") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('website')} >Website</div>
                                <div className={filters.includes("animation") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('animation')} >Animation</div>
                                <div className={filters.includes("medicine") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('medicine')} >Medicine</div>
                                <div className={filters.includes("3d") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('3d')} >3D Tour</div>
                                <div className={filters.includes("games") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('games')} >Games</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-projects'  id='grid-project-portfolio'>
                    {chunkArr(state)?.map((element, _i) => (
                        <div key={_i} className='grid-wrapper-projects'>
                            {element.map((item, index) => (
                            <div key={index} className={`wrapper-item-grid box-${index + 1}`} 
                            onClick={() => window.open(`${item.url}`, '_blank')} >
                                <div className='item-grid'  style={{backgroundImage: `url(${item.image})`}} ></div>
                                <div className='hover-effect-item-grid'>{item.title} <Arrow /> </div>
                            </div>
                            ))}
                        </div>
                    ))}
                </div>
            </ div>
            {filterAcrive ? <>
                <div className='filter-container-content-fixed'>
                    {toggleFilter ? <div className='filter-content-fixed'>
                        <div className='body-filter-content-fixed'>
                            <div className={filters.includes("product") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('product')} >Product</div>
                            <div className={filters.includes("configurator") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('configurator')} >Configurator</div>
                            <div className={filters.includes("ar") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('ar')} >AR</div>
                            <div className={filters.includes("crypto") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('crypto')} >Crypto</div>
                            <div className={filters.includes("construction") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('construction')} >Construction</div>
                            <div className={filters.includes("website") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('website')} >Website</div>
                            <div className={filters.includes("animation") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('animation')} >Animation</div>
                            <div className={filters.includes("medicine") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('medicine')} >Medicine</div>
                            <div className={filters.includes("3d") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('3d')} >3D Tour</div>
                            <div className={filters.includes("games") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('games')} >Games</div>
                        </div>
                    </div> : null}
                    <div className='toggle-filter-content-fixed desctop' onClick={() => {setToggleFilter(prev => !prev)}}><Filter /> Filter</div>
                </div>
                <div className='toggle-filter-content-fixed mobile' onClick={() => {setToggleFilter(prev => !prev)}}><Filter /> Filter</div>
            </> : null}
        </>
    );
};

export default OurPortfolio;