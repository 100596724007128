import React from "react";

// import client1 from "../../assets/clients/LogoCell1.svg";
import client2 from "../../assets/clients/LogoCell2.svg";
import client3 from "../../assets/clients/LogoCell3.svg";
import client4 from "../../assets/clients/LogoCell4.svg";
import client5 from "../../assets/clients/LogoCell5.svg";
import client6 from "../../assets/clients/LogoCell6.svg";
import client7 from "../../assets/clients/LogoCell7.svg";
import client8 from "../../assets/clients/LogoCell8.svg";

import client9 from "../../assets/clients/LogoCell9.svg";

import logoelectron from "../../assets/logo-development/logo-electron.svg";
import logoexpress from "../../assets/logo-development/logo-express.svg";
import logofirebase from "../../assets/logo-development/logo-firebase.svg";
import logojavascript from "../../assets/logo-development/logo-javascript.svg";
import logomarketplaces from "../../assets/logo-development/logo-marketplaces.svg";
import logometaverse from "../../assets/logo-development/logo-metaverse.svg";
import logomongo from "../../assets/logo-development/logo-mongo.svg";
import logonft from "../../assets/logo-development/logo-nft.svg";
import logonodejs from "../../assets/logo-development/logo-nodejs.svg";
import logop2p from "../../assets/logo-development/logo-p2p.svg";
import logoreact from "../../assets/logo-development/logo-react.svg";
import logosmartcontracts from "../../assets/logo-development/logo-solidity-smartcontracts.svg";
import logothreejs from "../../assets/logo-development/logo-threejs.svg";
import logounrealengine from "../../assets/logo-development/logo-unrealengine.svg";
import logowebgl from "../../assets/logo-development/logo-webgl.svg";

import Lottie from "lottie-react";
import dev from "../../assets/dev.json";
import web from "../../assets/web.json";
// import phone from '../../assets/phone.json';
import blockchain from "../../assets/blockchain.json";
import be from "../../assets/be.json";

import { ReactComponent as Star } from "../../assets/star.svg";
import { ReactComponent as IconBTN } from "../../assets/iconBTN.svg";

import { ReactComponent as Clutch } from "../../assets/clutch.svg";
import { ReactComponent as Upwork } from "../../assets/upwork.svg";

import servicesbg from "../../assets/services-bg.svg";
import servicesbgmini from "../../assets/services-bg-mini.svg";

import { ReactComponent as Arrow } from "../../assets/arrow.svg";

import todorov from "../../assets/todorov.png";
import { Link } from "react-scroll";

import "./style.scss";

const Services = () => {
  return (
    <div className="wrapper-services-t3d" id="services">
      <div className="container-content-services">
        <div className="header-content-services">
          <div className="title-header-services">
            Technology <span>Stack</span>
          </div>
        </div>
        <div className="block-development-services">
          <div className="wripper-grid-development-services">
            <div className="item-grid-development-services item-1">
              <div
                className="point-block-development-services"
                style={{ background: "#CACACA" }}
              ></div>
              <Lottie
                className="lottie-development-services-dev"
                animationData={dev}
                loop={true}
                autoplay={true}
              />
              <div className="title-tags-development-services">
                <div className="title-block-development-services">
                  3D Development
                </div>
                <div className="tags-services">
                  <span className="tags-grey">
                    {" "}
                    <img src={logowebgl} alt="" /> WebGL
                  </span>
                  <span className="tags-grey">
                    <img src={logothreejs} alt="" /> Three.js
                  </span>
                  <span className="tags-grey">
                    {" "}
                    <img src={logounrealengine} alt="" /> Unreal Engine
                  </span>
                </div>
              </div>
            </div>
            <div className="item-grid-development-services item-2">
              <div
                className="point-block-development-services"
                style={{ background: "#ffffff" }}
              ></div>
              <Lottie
                className="lottie-development-services-web"
                animationData={web}
                loop={true}
                autoplay={true}
              />
              <div className="title-tags-development-services">
                <div className="title-block-development-services">
                  FrontEnd Development
                </div>
                <div className="tags-services left">
                  <span>
                    <img src={logojavascript} alt="" /> JavaScript
                  </span>
                  <span>
                    <img src={logoreact} alt="" /> React
                  </span>
                  <span>
                    {" "}
                    <img src={logoelectron} alt="" />
                    Electron
                  </span>
                </div>
              </div>
            </div>
            <div className="item-grid-development-services item-3">
              <div
                className="point-block-development-services"
                style={{ background: "#CACACA" }}
              ></div>
              <Lottie
                className="lottie-development-services-phone"
                animationData={be}
                loop={true}
                autoplay={true}
              />
              <div className="title-tags-development-services">
                <div className="title-block-development-services">
                  BackEnd Development
                </div>
                <div className="tags-services left">
                  <span className="tags-grey">
                    <img src={logonodejs} alt="" />
                    Node.Js
                  </span>
                  <span className="tags-grey">
                    <img src={logoexpress} alt="" />
                    Express
                  </span>
                  <span className="tags-grey">
                    <img src={logomongo} alt="" />
                    Mongo
                  </span>
                  <span className="tags-grey">
                    <img src={logofirebase} alt="" />
                    Firebase
                  </span>
                </div>
              </div>
            </div>
            <div className="item-grid-development-services item-4">
              <div
                className="point-block-development-services"
                style={{ background: "#ffffff" }}
              ></div>
              <Lottie
                className="lottie-development-services-blockchain"
                animationData={blockchain}
                loop={true}
                autoplay={true}
              />
              <div className="title-tags-development-services">
                <div className="title-block-development-services">
                  Blockchain Development
                </div>
                <div className="tags-services left">
                  <span>
                    <img src={logosmartcontracts} alt="" />
                    SmartContracts
                  </span>
                  <span>
                    <img src={logometaverse} alt="" />
                    MetaVerse
                  </span>
                  <span>
                    <img src={logop2p} alt="" />
                    P2p
                  </span>
                  <span>
                    <img src={logonft} alt="" />
                    NFT
                  </span>
                  <span>
                    <img src={logomarketplaces} alt="" />
                    MarketPlaces
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-block-clients-services">
          <div className="block-clients-services">
            <div className="subtitle-content-services padding">
              {"Partners & Clients"}
            </div>
            <div className="list-clients">
              <div className="item-client client-1">
                <img src={client9} alt="" />
              </div>
              <div className="item-client client-2">
                <img src={client2} alt="" />
              </div>
              <div className="item-client client-3">
                <img src={client3} alt="" />
              </div>
              <div className="item-client client-4">
                <img src={client4} alt="" />
              </div>
              <div className="item-client client-5">
                <img src={client5} alt="" />
              </div>
              <div className="item-client client-6">
                <img src={client6} alt="" />
              </div>
              <div className="item-client client-7">
                <img src={client7} alt="" />
              </div>
              <div className="item-client client-8">
                <img src={client8} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="container-block-todorov-services">
          <div className="block-todorov-services">
            <div className="the-best-block-todorov-services min-size">
              <div
                className="subtitle-content-services todor"
                onClick={() =>
                  window.open(
                    "https://www.upwork.com/resources/2020-top-freelancers-and-agencies-ukraine",
                    "_blank"
                  )
                }
              >
                {"Our CEO  — Maxim Todorov is the Best Developer 2020"}{" "}
                <div className="icon-subtitle-content-services">
                  <IconBTN />
                </div>{" "}
              </div>
              <div className="tags-services left">
                <span className="tags-grey">Web</span>
                <span className="tags-grey">Mobile</span>
                <span className="tags-grey">Software</span>
              </div>
            </div>

            <div className="body-block-todorov-services max-size">
              <div className="left-todorov-services">
                <div className="the-best-block-todorov-services max-size">
                  <div
                    className="subtitle-content-services todor"
                    onClick={() =>
                      window.open(
                        "https://www.upwork.com/resources/2020-top-freelancers-and-agencies-ukraine",
                        "_blank"
                      )
                    }
                  >
                    {"Our CEO  — Maxim Todorov is the Best Developer 2020"}{" "}
                    <div className="icon-subtitle-content-services">
                      <IconBTN />
                    </div>{" "}
                  </div>
                  <div className="tags-services left">
                    <span className="tags-grey">Web</span>
                    <span className="tags-grey">Mobile</span>
                    <span className="tags-grey">Software</span>
                  </div>
                </div>
                <div className="block-info-todorov">
                  <div className="title-block-info-todorov">
                    <Star />
                    <span>Expert Vetted</span>
                  </div>
                  <div className="discription-block-info-todorov">
                    Expert-Vetted Talent is the top 1% — pre-screened by Upwork
                    Talent Managers and experts in their field.
                  </div>
                  <div className="btns-block-info-todorov">
                    <div
                      className="item-btn-block-info-todorov clutch"
                      onClick={() =>
                        window.open(
                          "https://clutch.co/profile/todor3d",
                          "_blank"
                        )
                      }
                    >
                      <Clutch /> <IconBTN className="svg-icon" />
                    </div>
                    <div
                      className="item-btn-block-info-todorov upwork"
                      onClick={() =>
                        window.open(
                          "https://www.upwork.com/freelancers/~014bd779e1c34ae5bc?viewMode=1",
                          "_blank"
                        )
                      }
                    >
                      <Upwork /> <IconBTN className="svg-icon" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="right-todorov-services">
                <div className="content-right-todorov-services">
                  <Link
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={1200}
                  >
                    <span>Contact</span> <Arrow />
                  </Link>
                  <img src={servicesbg} alt="" />
                </div>
              </div>

              {/* 
                                <div className='right-todorov-services'>
                                    <div className='content-right-todorov-services'> 
                                        <div > <span>Contact</span> <Arrow /></div>
                                        <img src={servicesbg} alt="" />
                                    </div>
                                </div> */}

              <img className="img-todorov" src={todorov} alt="" />
            </div>
            <div className="body-block-todorov-services min-size">
              <div className="container-todorov-mini-bg">
                <img
                  className="bg-services-todorov"
                  src={servicesbgmini}
                  alt=""
                />
                <img className="img-todorov-mini" src={todorov} alt="" />
              </div>
              <div className="btns-block-info-todorov">
                <div
                  className="item-btn-block-info-todorov clutch"
                  onClick={() =>
                    window.open("https://clutch.co/profile/todor3d", "_blank")
                  }
                >
                  <Clutch /> <IconBTN className="svg-icon" />
                </div>
                <div
                  className="item-btn-block-info-todorov upwork"
                  onClick={() =>
                    window.open(
                      "https://www.upwork.com/freelancers/~014bd779e1c34ae5bc?viewMode=1",
                      "_blank"
                    )
                  }
                >
                  <Upwork /> <IconBTN className="svg-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
