import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import videoMaxSizeWEBM from "../../assets/video-maxSize.webm";
import videoMaxSizeMP4 from "../../assets/video-maxSize.mp4";

import videoMiniSizeWEBM from "../../assets/video-miniSize.webm";
import videoMiniSizeMP4 from "../../assets/video-miniSize.mp4";

import logoT3D from "../../assets/logoT3D.svg";
import ItemInfoHome from "../../component/ItemInfoHome";

import ScrollDisabler from "../../ScrollDisabler";

const Home = () => {
  const [sizeVideo, setSizeVideo] = useState();
  const videoHome = useRef();

  useEffect(() => {
    videoHome.current?.load();
    videoHome.current.playbackRate = 0.6;
  }, [sizeVideo]);

  const hiddenLoader = () => {
    setTimeout(() => {
      document.getElementById("wrapper-loader").classList.add("hidden");
      setTimeout(() => {
        ScrollDisabler.enable();
      }, 1000);
    }, 3000);
  };

  useEffect(() => {
    handleResize();
  }, []);

  const [loadedVideo, setLoadedVideo] = useState(false);

  useEffect(() => {
    if (!loadedVideo) return;
    hiddenLoader();
  }, [loadedVideo]);

  const handleResize = () => {
    if (window.innerWidth / window.innerHeight >= 0.9) {
      setSizeVideo(false);
    } else {
      setSizeVideo(true);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="wrapper-home-t3d" id="home">
      <div className="container-home-t3d">
        <video
          muted="true"
          loop
          autoPlay
          playsInline
          ref={videoHome}
          onPlay={() => {
            setLoadedVideo(true);
          }}
          style={
            sizeVideo
              ? { objectPosition: "50% 100%" }
              : { objectPosition: "100% 100%" }
          }
        >
          <source
            src={sizeVideo ? videoMiniSizeWEBM : videoMaxSizeWEBM}
            type="video/webm"
          />
          <source
            src={sizeVideo ? videoMiniSizeMP4 : videoMaxSizeMP4}
            type="video/mp4;codecs=hvc1"
          />
        </video>
        <div className="container-content-home">
          <img className="logo-home-t3d" src={logoT3D} alt="" />
          <div className="center-block-home-t3d">
            <div className="title-home-t3d">
              <>Creating Cutting-Edge</>
              <span>3D/AR/VR</span>
              <>Web & Mobile Solutions</>
            </div>

            <div className="title-home-t3d br">
              <>
                Creating <br /> Cutting-Edge
              </>
              <span>3D/AR/VR</span>
              <>
                Web & Mobile <br /> Solutions
              </>
            </div>

            <div className="info-company-home-t3d">
              <ItemInfoHome qty={"23"} title={"Developers"} />
              <ItemInfoHome qty={"7"} title={"Managers"} />
              <ItemInfoHome qty={"5"} title={"Designers"} />
              <ItemInfoHome qty={"3+"} title={"Years"} />
              <ItemInfoHome qty={"100+"} title={"Projects"} />
            </div>
          </div>
          <div className="note-home-t3d">
            <div className="line-note-home-t3d"></div>
            <div className="text-note-home-t3d">
              Breaking Boundaries with WebGL
            </div>
            <div className="text-note-home-t3d">
              <span>#WebGL</span>
              <span>#ThreeJS</span>
              <span>#8thWall</span>
              <span>#Magic</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
