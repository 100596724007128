import { useState } from "react";
import Navigator from "./component/Navigator";
import Contact from "./main/Contact";
import Home from "./main/Home";
import OurPortfolio from "./main/OurPortfolio";
import RecentWorks from "./main/RecentWorks";
import Services from "./main/Services";
import { Route, Routes } from "react-router-dom";
import "./style/style.scss";

import virtualmuseumimage from "./assets/works/preview_virtualmuseum.jpg";
import clioimage from "./assets/works/preview_clio.jpg";
import dogeraceimage from "./assets/works/preview_dogerace.jpg";
import fwimage from "./assets/works/preview_fw.jpg";
import motionshoesimage from "./assets/works/preview_motionshoes.jpg";
import supraimage from "./assets/works/preview_supra.jpg";
import teslaboatimage from "./assets/works/preview_teslaboat.jpg";
import theneighrboursimage from "./assets/works/preview_theneighrbours.jpg";
import yamahaimage from "./assets/works/preview_yamaha.jpg";
import universimage from "./assets/works/preview_univers.jpg";
import legocityimage from "./assets/works/preview_legocity.jpg";

import funkypassimage from "./assets/works/preview_funkypass.jpg";
import beloudimage from "./assets/works/preview_beloud.jpg";
import clotherealmimage from "./assets/works/preview_clotherealm.jpg";
import clubgorgonimage from "./assets/works/preview_clubgorgon.jpg";
import cubeanimationimage from "./assets/works/preview_cubeanimation.jpg";
import galleryd10image from "./assets/works/preview_galleryd10.jpg";
import homes3Dimage from "./assets/works/preview_homes3D.jpg";
import morphcreatorimage from "./assets/works/preview_morphcreator.jpg";
import neohuntersimage from "./assets/works/preview_neohunters.jpg";
import paniviewerimage from "./assets/works/preview_paniviewer.jpg";
import particlesimage from "./assets/works/preview_particles.jpg";
import showroomimage from "./assets/works/preview_showroom.jpg";
import teamwiseimage from "./assets/works/preview_teamwise.jpg";
import trinityimage from "./assets/works/preview_trinity.jpg";
import virtuentimage from "./assets/works/preview_virtuent.jpg";
import zenhouseimage from "./assets/works/preview_zenhouse.jpg";

import airpodsimage from "./assets/works/preview_airpods.jpg";
import districtimage from "./assets/works/preview_district.jpg";
import greenhouseimage from "./assets/works/preview_greenhouse.jpg";
import jawimage from "./assets/works/preview_jaw.jpg";
import skybudsimage from "./assets/works/preview_skybuds.jpg";
import slabbinkimage from "./assets/works/preview_slabbink.jpg";
import tombstoneconfiguratorimage from "./assets/works/preview_tombstoneconfigurator.jpg";
import umbrellaimage from "./assets/works/preview_umbrella.jpg";
import uterusimage from "./assets/works/preview_uterus.jpg";
import viteappimage from "./assets/works/preview_viteapp.jpg";
import wenblockimage from "./assets/works/preview_wenblock.jpg";
import lego2 from "./assets/works/preview_lego2.jpg";

import homez from "./assets/works/preview_homez.jpg";

import image3615 from "./assets/works/preview_3615.jpg";

import yslimage from "./assets/works/preview_ysl.jpg";
import arcatalogueimage from "./assets/works/preview_arcatalogue.jpg";

import ReactGA from "react-ga4";

import { useEffect } from "react";
// import CrispChat from "./CrispChat";

const App = () => {
  const state = [
    {
      url: "https://todor3d.com/portfolio/funky_wolves/",
      title: "Funky Wolves",
      image: fwimage,
      key: "recent",
      tag: ["crypto", "website", "animation"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/motion-shoes",
      title: "Motion Shoes",
      image: motionshoesimage,
      key: "recent",
      tag: ["configurator", "product"],
      devices: "any",
    },
    {
      url: "https://dogetrack.io/",
      title: "Doge Race",
      image: dogeraceimage,
      key: "recent",
      tag: ["crypto"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/boat-configurator/",
      title: "Tesla Boat",
      image: teslaboatimage,
      key: "recent",
      tag: ["configurator", "product"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/museum/",
      title: "Virtual Museum",
      image: virtualmuseumimage,
      key: "recent",
      tag: ["3d"],
      devices: "any",
    },
    {
      url: "https://www.8thwall.com/blog/post/48661389999/toyota-supra-turns-road-track-magazine-into-interactive-webar-experience",
      title: "Supra",
      image: supraimage,
      key: "recent",
      tag: ["ar"],
      devices: "any",
    },
    {
      url: "https://metaverse.todor3d.com/",
      title: "The Neighbours",
      image: theneighrboursimage,
      key: "recent",
      tag: ["crypto", "games"],
      devices: "desctop",
    },
    {
      url: "https://youtube.com/shorts/0fZPlNoFjOw",
      title: "Lego City",
      image: legocityimage,
      key: "recent",
      tag: ["ar"],
      devices: "mobile",
    },
    {
      url: "https://todor3d.com/portfolio/rings-configurator/",
      title: "Jewerly Configurator",
      image: clioimage,
      key: "recent",
      tag: ["configurator"],
      devices: "any",
    },
    {
      url: "https://youtube.com/shorts/t6eWaE4ZKL4/",
      title: "Yamaha",
      image: yamahaimage,
      key: "recent",
      tag: ["ar"],
      devices: "any",
    },
    {
      url: "https://universe.todor3d.com/",
      title: "Universe",
      image: universimage,
      key: "recent",
      tag: ["crypto", "website"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/card-funkypass/",
      title: "Funky Pass",
      image: funkypassimage,
      tag: ["product"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/gallery/",
      title: "Gallery D10",
      image: galleryd10image,
      tag: ["3d"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/pano-viewer/",
      title: "Pano Viewer",
      image: paniviewerimage,
      tag: ["3d"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/showroom2/",
      title: "ShowRoom",
      image: showroomimage,
      tag: ["3d"],
      devices: "any",
    },
    // {
    //   url: "https://neohunters.io/",
    //   title: "NeoHunters",
    //   image: neohuntersimage,
    //   tag: ["crypto"],
    //   devices: "any",
    // },
    {
      url: "https://todor3d.com/portfolio/club-gorgon/",
      title: "Club Gorgon",
      image: clubgorgonimage,
      tag: ["website"],
      devices: "any",
    },
    {
      url: "https://www.triiinity.com.ua/",
      title: "Triniiiti",
      image: trinityimage,
      tag: ["website"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/virtuent/",
      title: "Virtuent",
      image: virtuentimage,
      tag: ["website"],
      devices: "any",
    },
    {
      url: "https://zenhouz.com.ua/",
      title: "ZenHouse",
      image: zenhouseimage,
      tag: ["website"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/cube-animation/",
      title: "Cube-Animation",
      image: cubeanimationimage,
      tag: ["animation"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/particles/",
      title: "Particles",
      image: particlesimage,
      tag: ["animation"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/clotherealm/",
      title: "Clotherealm",
      image: clotherealmimage,
      tag: ["animation"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/beloud/",
      title: "Beloud",
      image: beloudimage,
      tag: ["animation"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/homes3d/",
      title: "Homes3D",
      image: homes3Dimage,
      tag: ["construction"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/morph-creator/",
      title: "Morph-Creator",
      image: morphcreatorimage,
      tag: ["construction"],
      devices: "any",
    },
    {
      url: "https://teamwise.space/",
      title: "TeamWise",
      image: teamwiseimage,
      tag: ["construction"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/airpods/",
      title: "Airpods",
      image: airpodsimage,
      tag: ["product", "animation"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/greenhouse-v2",
      title: "GreenHouse",
      image: greenhouseimage,
      tag: ["construction"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/metro-demo",
      title: "District",
      image: districtimage,
      tag: ["construction"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/vite-app-dev/",
      title: "ViteApp",
      image: viteappimage,
      tag: ["construction"],
      devices: "any",
    },
    {
      url: "https://skybuds.todor3d.com/",
      title: "SkyBuds",
      image: skybudsimage,
      tag: ["games"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/umbrella/",
      title: "Umbrella",
      image: umbrellaimage,
      tag: ["configurator"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/slabbinck/",
      title: "Slabbinck",
      image: slabbinkimage,
      tag: ["configurator"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/tombstone-editor/",
      title: "Tombstone Configurator",
      image: tombstoneconfiguratorimage,
      tag: ["configurator"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/uterus-anatomy/",
      title: "Uterus",
      image: uterusimage,
      tag: ["medicine"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/jaw/",
      title: "JAW",
      image: jawimage,
      tag: ["medicine"],
      devices: "any",
    },
    {
      url: "https://youtu.be/v08KD_SDwH8",
      title: "YSL",
      image: yslimage,
      tag: ["ar"],
      devices: "any",
    },
    {
      url: "https://youtube.com/shorts/L6hb34TxmmQ?feature=share",
      title: "AR Catalogue",
      image: arcatalogueimage,
      tag: ["ar"],
      devices: "any",
    },

    {
      url: "https://todor3d.com/portfolio/wenblock/",
      title: "Wenblock",
      image: wenblockimage,
      tag: ["website"],
      devices: "any",
    },
    {
      url: "https://todor3d.com/portfolio/3615/",
      title: "3615",
      image: image3615,
      tag: ["website", "crypto", "animation"],
      devices: "any",
    },

    {
      url: "https://www.youtube.com/watch?v=ByhW6MXHATY",
      title: "Lego Market",
      image: lego2,
      tag: ["ar"],
      devices: "any",
    },

    {
      url: "https://todor3d.com/portfolio/viewer-homez/",
      title: "Homez",
      image: homez,
      tag: ["construction"],
      devices: "any",
    },
  ];

  const [waiting, setWaiting] = useState(false);
  const [filters, setFilters] = useState([]);
  const [toggleFilter, setToggleFilter] = useState(false);
  // const [section, setSection] = useState('');
  // const [blackout, setBlackout] = useState(false)
  const [accessScroll, setAccessScroll] = useState(true);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // },[section])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, []);

  ReactGA.initialize("G-M5CXPEVD7Q");

  return (
    <div className="wrapper-app-t3d">
      {/* <div className={blackout ? 'blackout active' : 'blackout'}></div> */}
      {waiting ? (
        <div className="wrapper-waiting">
          <div className="spinner">
            <div className="blob top"></div>
            <div className="blob bottom"></div>
            <div className="blob left"></div>
            <div className="blob move-blob"></div>
          </div>
        </div>
      ) : null}

      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <Navigator
                accessScroll={accessScroll}
                setAccessScroll={setAccessScroll}
                setFilters={setFilters}
              />
              <div className="wrapper-main" id="wrapper-main">
                <Home />
                <RecentWorks
                  state={state}
                  setFilters={setFilters}
                  filters={filters}
                  setToggleFilter={setToggleFilter}
                  toggleFilter={toggleFilter}
                />
                <Services />
                <Contact setWaiting={setWaiting} />
              </div>
            </>
          }
        />
        <Route
          path="/our-portfolio"
          element={
            <OurPortfolio
              state={state}
              setToggleFilter={setToggleFilter}
              toggleFilter={toggleFilter}
              setFilters={setFilters}
              filters={filters}
            />
          }
        />
      </Routes>
      {/* <CrispChat /> */}
    </div>
  );
};

export default App;
