import React, {useRef} from 'react';
import { ReactComponent as ArrowMini} from '../../assets/arrow-mini.svg';
import { ReactComponent as Filter} from '../../assets/filter.svg';

import Lottie from 'lottie-react';
import portfolio from '../../assets/portfolio.json';

import {ReactComponent as Arrow} from '../../assets/arrow-title.svg'
import checkMobile from '../../checkMobile.js';

// import ReactGA from "react-ga4";


import './style.scss';
import { Link } from 'react-router-dom';



const RecentWorks = ({state, filters, setFilters, toggleFilter, setToggleFilter }) => {



    const chunkArr = (array) => {

        const devices = checkMobile() ? 'mobile' : 'desctop'
        array = array.filter(item => item.key === "recent" &&  (item.devices === devices || item.devices === 'any') ) 
        // if(filters.length){
        //     array = array.filter(item => item.tag?.find(el => filters.includes(el)));
        // }
        let size = 10; 
        let subarray = []; 
        for (let i = 0; i <Math.ceil(array.length/size); i++){
            subarray[i] = array.slice((i*size), (i*size) + size);
        }
        return subarray;
    }
    const reflottiePortfolio = useRef()
    const changeFilter = (value) => {
        if(filters.includes(value)){
            setFilters(prev => prev.filter(item => item !== value))
        }else{
            setFilters(prev => [...prev, value])
        }
    }
    // const [filterAcrive, SetfilterActive] = useState(false);

    return (
        <> 
            {false ? <>
                <div className='filter-container-content-fixed'>
                    {toggleFilter ? <div className='filter-content-fixed'>
                        <div className='body-filter-content-fixed'>
                            <Link to='/our-portfolio' className={filters.includes("product") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('product')} >Product</Link>
                            <Link to='/our-portfolio' className={filters.includes("configurator") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('configurator')} >Configurator</Link>
                            <Link to='/our-portfolio' className={filters.includes("ar") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('ar')} >AR</Link>
                            <Link to='/our-portfolio' className={filters.includes("crypto") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('crypto')} >Crypto</Link>
                            <Link to='/our-portfolio' className={filters.includes("construction") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('construction')} >Construction</Link>
                            <Link to='/our-portfolio' className={filters.includes("website") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('website')} >Website</Link>
                            <Link to='/our-portfolio' className={filters.includes("animation") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('animation')} >Animation</Link>
                            <Link to='/our-portfolio' className={filters.includes("medicine") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('medicine')} >Medicine</Link>
                            <Link to='/our-portfolio' className={filters.includes("3d") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('3d')} >3D Tour</Link>
                            <Link to='/our-portfolio' className={filters.includes("games") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('games')} >Games</Link>
                        </div>
                    </div> : null}
                    <div className='toggle-filter-content-fixed desctop' onClick={() => {setToggleFilter(prev => !prev)}}><Filter />Filter</div>
                </div> 
                <div className='toggle-filter-content-fixed mobile' onClick={() => {setToggleFilter(prev => !prev)}}><Filter />Filter</div>
            </> : null}
            <div className='wrapper-recent-works-t3d' id='recent-works'>
                <div className='container-content-recent-works'> 
                    <div className="header-content-recent-works">
                        <div className='title-header-recent-works'>Recent <span>Works</span></div>
                    </div>
                    <div className='filter-container-content'>
                        <div className='filter-content'>
                            <Link to='/our-portfolio' className={filters.includes("product") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('product')} >Product</Link>
                            <Link to='/our-portfolio' className={filters.includes("configurator") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('configurator')} >Configurator</Link>
                            <Link to='/our-portfolio' className={filters.includes("ar") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('ar')} >AR</Link>
                            <Link to='/our-portfolio' className={filters.includes("crypto") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('crypto')} >Crypto</Link>
                            <Link to='/our-portfolio' className={filters.includes("construction") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('construction')} >Construction</Link>
                            <Link to='/our-portfolio' className={filters.includes("website") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('website')} >Website</Link>
                            <Link to='/our-portfolio' className={filters.includes("animation") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('animation')} >Animation</Link>
                            <Link to='/our-portfolio' className={filters.includes("medicine") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('medicine')} >Medicine</Link>
                            <Link to='/our-portfolio' className={filters.includes("3d") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('3d')} >3D Tour</Link>
                            <Link to='/our-portfolio' className={filters.includes("games") ? 'item-filter active' : 'item-filter'} onClick={() => changeFilter('games')} >Games</Link>
                        </div>
                    </div>
                </div>
                <div className='container-projects' id='grid-project-portfolio'>
                    {chunkArr(state)?.map((element, _i) => (
                        <div key={_i} className='grid-wrapper-projects'>
                            {element.map((item, index) => (
                            <div key={index} className={`wrapper-item-grid box-${index + 1}`} 
                            onClick={() => window.open(`${item.url}`, '_blank')} >
                                <div className='item-grid'  style={{backgroundImage: `url(${item.image})`}} ></div>
                                <div className='hover-effect-item-grid'>{item.title} <Arrow /> </div>
                            </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className='wrapper-btn-all-projects'> 
                    <Link  to='/our-portfolio' className='btn-all-projects'
                    onMouseEnter={() => {
                        reflottiePortfolio.current.setDirection(1)
                        reflottiePortfolio.current.play();
                    }}
                    onMouseLeave={() => {
                        reflottiePortfolio.current.setDirection(-1)
                        reflottiePortfolio.current.play();
                    }}
                    >
                        <div className='left-btn-all-projects'>
                            <Lottie
                                lottieRef={reflottiePortfolio}
                                className='portfolio-lottie-btn'
                                animationData={portfolio}
                                loop={false}
                                autoplay={false}
                            />
                            <span>Check our amazing portfolio</span>
                            <div className='qty-btn-all-projects'>{state.length} projects</div>
                            </div>
                        <div className='right-btn-all-projects'><ArrowMini className='arrow-btn-all-projects'/></div>
                    </Link>
                </div>
            </ div>
        </>
    );
};

export default RecentWorks;