import Lottie from 'lottie-react';
import { useEffect, useRef } from 'react';
import logo from './assets/logo.json'
import ScrollDisabler from './ScrollDisabler';

const Loader = () => {
    const ref = useRef();

    useEffect(() => {
       ScrollDisabler.disable();
    }, [])

    const reflottieLogo = useRef;

    useEffect(() =>{
        reflottieLogo.current.setSpeed(2)
    },[])

    return (
        <div className="wrapper-loader" id="wrapper-loader">
            <Lottie
                lottieRef={reflottieLogo}
                className='logo-lottie'
                animationData={logo}
                autoplay={true}
            />
        </div>
    )};
  
  export default Loader;