import React from 'react';
import './style.scss';

const ItemInfoHome = ({qty, title}) => {
    return (
        <div className='wrapper-item-info-home'>
            <div className='qty-item-info-home'>{qty}</div>
            <div className='title-item-info-home'>{title}</div>
        </ div>
    );
};

export default ItemInfoHome;